import React from 'react';
import { Link } from 'gatsby'
import { Wrapper } from './styles'

const CoveredList = () => {
    return (
        <Wrapper>
            <article className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="section-title">
                            <h2>We’ve got you covered<span>.</span></h2>
                            <p>
                                We know that every company has unique set of currency needs and that it's impossible to reduce currency volatility to zero.<br /><br />
                                That's why we work tirelessly towards a single mission: helping our clients take control of their currency risk.<br /><br />
                                Let us do the heavy lifting, and ask for an ARCH demo today.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="got-covered-list">
                            <ul>
                                <li>Protect against Loss</li>
                                <li>Drive growth Opportunities</li>
                                <li>Real-time Insights</li>
                                <li>Netting your Exposure</li>
                                <li>Automation of Execution</li>
                                <li>Increase data transparency</li>
                                <li>Easily and quickly display results</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </article>
            <article className="container">
                <div className="top-btn">
                    <Link className="btn btn-primary aqua list-button" to="/demo">Try Demo</Link>
                </div>
            </article>
        </Wrapper>
    );
}

export default CoveredList
