import styled from 'styled-components'

const Wrapper = styled.section`
  margin: 55px 0;
  h2 {
    font-size: 37px;
    font-weight: bold;
  }
  ul {
    margin: 80px 0 0 60px;
    padding: 0;
    list-style-type: none;
    li {
      line-height: 1.14;
      letter-spacing: 0.25px;
      font-size: 21px;
      margin: 0 0 19px;
      position: relative;
      font-weight: bold;
      &::before {
        content: '.';
        font-size: 70px;
        display: block;
        position: absolute;
        left: -17px;
        top: -49px;
        color: ${props => props.theme.colors.aquaBlue};
      }
    }
  }
  .section-title p {
    font-size: 18px;
    margin-top: 11px;
    line-height: 1.22;
    letter-spacing: 0.22px;
  }
  .section-title h2 {
    margin: 0 0 28px;
  }
  .list-button {
    margin: -110px 0 0 0px;
    position: relative;
    z-index: 10;
  }

  @media (max-width: ${props => props.theme.responsive.limit}) {
    .list-button {
      margin: -20px 0 0 0px;
    }
  }

  @media (max-width: ${props => props.theme.responsive.large}) {
    ul {
      margin: 120px 0 0 10px;
      li {
        line-height: 24px;
        font-size: 16px;
        letter-spacing: 0.19px;
        &::before {
          top: -15px;
          left: -15px;
          font-size: 50px;
        }
      }
    }
    .list-button {
      margin: 0px 0 0 0px;
    }
    .section-title p {
      padding: 0 0px;
    }
    .section-title h2 {
      padding: 0 0px;
    }
  }

  @media (max-width: ${props => props.theme.responsive.medium}) {
    ul{
      margin:0 25px 0 10px;
    }
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    background: ${props => props.theme.colors.backgroundGrey};
    margin: 55px 0 0;
    padding: 50px 15px;
    p {
      line-height: 1.25;
      font-size: 16px;
    }
    .section-title h2 {
      line-height: 25px;
      font-size: 24px;
    }
    ul li {

      font-size: 16px;
    }
  }


      /* padding: 90px 0;
    margin: 55px 0;
    
    &.got-covered {
      padding: 1px 0 0 0;
      .row {
        margin: 15px 0 0 0;
      }
    }
    .row {
      margin-top: 51px;
    }
    .card-body {
      padding: 0;
      .card-image-top{
        max-width: 80px;
      }
      .card-title {
        margin-top: 32px;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.04;
        letter-spacing: 0.29px;
        margin-bottom: 20px;
      }
    } */


    /* margin: 25px 0;
        ul {
          margin: 120px 0 0 10px;
          li {
            line-height: 24px;
            font-size: 16px;
            letter-spacing: 0.19px;
            &::before {
              top: -15px;
              left: -15px;
              font-size: 50px;
            }
          }
        }
        .section-title p {
          padding: 0 15px;
        }
        .section-title h2 {
          padding: 0 15px;
        } */


        /* @include media-breakpoint-down(sm) {
  main {
    .section {
      &_in-middle.got-covered {
        ul {
          margin: 20px 0 0 26px;
          li {
            font-weight: bold;
          }
        }
      }
    }
  }

      &_in-middle.got-covered {
        ul {
          margin: 30px 0 0 26px;
          li {
            font-weight: bold;
          }
        }
      }

} */

    
`

export { Wrapper }
