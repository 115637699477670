import styled from 'styled-components'

const Wrapper = styled.section`
  .payments-block {
    margin-top: 20px;
    img {
      width: auto;
      max-width: 80px;
    }
    .payment-item {
      padding-top: 40px;
      width:20%;
    }
    h5 {
      margin-top: 25px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  @media (max-width: ${props => props.theme.responsive.large}) {
    .payments-block {
      .payment-item {
        img {
          max-width: 70px;
        }
        h5 {
          font-size: 13px;
          padding: 0 10px;
        }
      }
    }
  }
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    margin-top: 43px;
    article .payments-block .payment-item{
      padding-top: 15px;
      img {
        max-width: 50px;
      }
      h5 {
        margin: 16px 0 16px 0;
      }
    }
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
  .payments-block {
      margin-top: -2px;
      .payment-item {
        padding-top: 25px;
        width:50%;
        &.monitoring {
          width: 100%;
        }
        img {
          max-width: 70px;
        }
        h5 {
          font-size: 16px;
          line-height: 18px;
          font-weight: normal;
        }
      }
    }
  }
`

export { Wrapper }
