import React from 'react';
import { Link } from 'gatsby'
import { Wrapper } from './styles'

const GotCovered = () => {
    return (
        <Wrapper>
            <article className="container">
                <div className="section-title text-centered">
                    <h2>The everyday currency platform<span>.</span></h2>
                    <p>
                        ARCH is a cloud based service platform that connects <br />
                        with your organisations travel booking software.<br /><br />

                        Enabling you to make the best management decisions, <br />
                        helps you to minimise risk and maximise profits.<br />
                    </p>
                    <div className="center top-btn">
                        <Link className="btn btn-primary aqua" to="/demo">Try Demo</Link>
                    </div>
                </div>
            </article>
        </Wrapper>
    );
}

export default GotCovered;
