import React from 'react'
import { Wrapper } from './styles'

import analysis from '../../images/risk-analysis.png'
import evaluation from '../../images/risk-evaluation.png'
import assesment from '../../images/risk-assesment.png'
import management from '../../images/risk-management.png'
import monitoring from '../../images/risk-monitoring.png'

const br = '<br />'

const list = [
    {id: '0', icon: analysis, title1: 'Risk', title2:'Analysis'},
    {id: '1', icon: evaluation, title1: 'Risk', title2:'Evaluation'},
    {id: '2', icon: assesment, title1: 'Risk Assessment', title2:'& Quantifying'},
    {id: '3', icon: management, title1: 'Risk Management', title2:'Policy'},
    {id: '4', icon: monitoring, class: 'monitoring', title1: 'Risk Monitoring', title2:'& Reporting'}
]

const WhiteFiveFeatures = () => {
    return (
        <Wrapper>
            <article className="container">
                <div className="row payments-block">
                    {list.map(feature => (
                        <div className={`text-center payment-item ${feature.class ? feature.class : ''}`} key={feature.id}>
                            <img src={feature.icon} alt={feature.title} />
                            <h5>{feature.title1}<br />{feature.title2}</h5>
                        </div>
                    ))}
                </div>
            </article>
        </Wrapper>
    );
}

export default WhiteFiveFeatures
