import styled from 'styled-components'

const Wrapper = styled.section`
  background: ${props => props.theme.colors.backgroundGrey};
  color: ${props => props.theme.colors.deepButtonBlue};
  padding: 90px 0;
  margin: 55px 0;
  .section-title {
    p {
      font-size: 18px;
      margin-top: 11px;
      line-height: 1.22;
      letter-spacing: 0.22px;
    }
    h2 {
      margin: 0 0 28px;
    }
  }
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin: 25px 0;
    .section-title p {
      padding: 0 15px;
      font-size: 16px;
      margin-top: 36px;      
    }
    .section-title h2 {
      padding: 0 15px;
      font-size: 24px;
    }
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    background: ${props => props.theme.colors.white};
    margin: 25px 0;
    padding: 50px 0 40px;
    .section-title p {
      padding: 0 15px;
      font-size: 16px;
      margin-top: 36px;
      line-height: 1.25;
    }
    .section-title h2 {
      padding: 0 15px;
      font-size: 24px;
    }
  }
`

export { Wrapper }
