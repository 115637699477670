import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SEOPageTitle from '../components/SEOPageTitle'
import BlueHero from '../components/BlueHero'
import WhiteFiveFeatures from '../components/WhiteFiveFeatures'
import GotCovered from '../components/GotCovered'
import WhiteFourFeatures from '../components/WhiteFourFeatures'
import BlueCallExpert from '../components/BlueCallExpert'
import WhiteThreeFeatures from '../components/WhiteThreeFeatures'
import CoveredList from '../components/CoveredList'
import Nicola from '../components/Nicola'

const Home = ({ data }) => {
  const seoNode = {
    title: 'Zoopay – Helping to Solve Currency and Payment Challenges',
    description:
      'Travel industry risk management made simple. ' +
      'The technology you need to solve all your currency and payment challenges.',
  }

  return (
    <Layout dark>
      <BlueHero teal />
      <WhiteFiveFeatures />
      <GotCovered teal />
      <WhiteFourFeatures teal />
      <BlueCallExpert gateway />
      <WhiteThreeFeatures teal/>
      <CoveredList />
      <Nicola />
      <SEOPageTitle metaTitle={seoNode.title} />
      <SEO postNode={seoNode} pagePath="" customTitle customDescription />
    </Layout>
  )
}

export default Home
